/* General Styles */
.faq-container {
    padding: 20px 20px;
    max-width: 1100px;
    margin: 0 auto;
    border-radius: 12px;
   
}

.faq-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
    border-bottom: 2px solid #333; /* Optional: if you want a border under the title */
}

.faq-list {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.faq-item {
    background-color: #CEE5FD;
    padding: 2px 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #333; /* Define the border here */
    border-radius: 10px;
}

.faq-question {
    font-size: 1.3rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #053F7E;
    transition: color 0.3s ease;
}

.faq-answer {
    font-size: 1rem;
    color: #555;
    margin-top: 15px;
    line-height: 1.5;
}

.arrowPlus {
    font-size: 1.8rem;
    color: #007bff;
    transition: transform 0.3s ease;
}
.arrowMinus {
    font-size: 1.8rem;
    color: #007bff;
    transition: transform 0.3s ease;
}

.loading-text {
    font-size: 1.2rem;
    color: #007bff;
    text-align: center;
    margin-top: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .faq-title {
        font-size: 1.5rem;
    }

    .faq-item {
        padding: 15px;
    }

    .faq-question {
        font-size: 1.1rem;
    }

    .faq-answer {
        font-size: 0.9rem;
    }

    .arrow {
        font-size: 1.5rem;
    }
}
