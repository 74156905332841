/* Fullscreen loader container */
.custom-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    /* Dark semi-transparent background */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    color: #fff;
}

/* Circular spinner */
.spinner {
    width: 60px;
    height: 60px;
    border: 6px solid transparent;
    border-top: 6px solid #36d7b7;
    /* Spinner color */
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
    margin-bottom: 20px;
}

/* Spinner animation */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Loading message */
.loader-message {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
}